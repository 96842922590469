<template>
  <c-flex flex-dir="column" flex="1" pb="5rem">
    <c-flex flex="1" flex-dir="column" px="20px">
      <c-flex
        v-if="!isEmpty"
        mt="10px"
        align-items="center"
        v-chakra="{ gap: '10px' }"
      >
        <c-image
          h="24px"
          w="24px"
          :src="require('@/assets/icon-user-filled.svg')"
          alt="icon user"
        />
        <c-text font-size="12px" font-weight="400" color="gray.900">
          Dibuat oleh {{ nutritionistName }} - Ahli gizi Dietela
        </c-text>
      </c-flex>
      <c-text mt="15px" font-size="16px" font-weight="700">
        Kondisi Giziku
      </c-text>
      <c-box v-if="isKondisiGizikuEmpty">
        <EmptyState title="Kondisi giziku belum terisi" />
      </c-box>
      <template v-else>
        <c-flex
          mt="15px"
          flex-dir="column"
          border="1px solid #C4C4C4"
          border-radius="8px"
          p="8px"
        >
          <c-flex align-items="center" v-chakra="{ gap: '10px' }">
            <c-text font-size="12px" font-weight="400">
              Indeks Masa Tubuh
            </c-text>
            <c-image
              h="18px"
              w="18px"
              :src="require('@/assets/icon-info-circle.svg')"
              alt="icon info"
            />
          </c-flex>
          <c-text mt="5px" font-size="16px" font-weight="700">
            {{ bmi }}kg/m2
          </c-text>
          <c-text mt="5px" font-size="14px" font-weight="400" color="#555">
            Status gizi mu: {{ statusGizi }}
          </c-text>
          <Progress :value="progressValue" v-chakra mt="10px" />
        </c-flex>
        <c-flex
          v-if="profileGizi"
          mt="10px"
          overflow-x="auto"
          v-chakra="{ gap: '8px' }"
        >
          <c-flex
            flex-dir="column"
            border="1px solid #c4c4c4"
            border-radius="8px"
            p="8px"
            flex-shrink="0"
          >
            <c-text font-size="12px" font-weight="400" color="#4F4F4F">
              Umur
            </c-text>
            <c-text font-size="16px" font-weight="700" color="#111">
              {{ profileGizi.age }} Tahun
            </c-text>
          </c-flex>
          <c-flex
            flex-dir="column"
            border="1px solid #c4c4c4"
            border-radius="8px"
            p="8px"
            flex-shrink="0"
          >
            <c-text font-size="12px" font-weight="400" color="#4F4F4F">
              Berat badan/Tinggi badan
            </c-text>
            <c-text font-size="16px" font-weight="700" color="#111">
              {{ profileGizi.weight | toFixed }}kg /
              {{ profileGizi.height | toFixed }}cm
            </c-text>
          </c-flex>
          <c-flex
            flex-dir="column"
            border="1px solid #c4c4c4"
            border-radius="8px"
            p="8px"
            flex-shrink="0"
          >
            <c-text font-size="12px" font-weight="400" color="#4F4F4F">
              BB ideal
            </c-text>
            <c-text font-size="16px" font-weight="700" color="#111">
              {{ profileGizi.idealWeightMinimum | toFixed }} -
              {{ profileGizi.idealWeightMaximum | toFixed }} kg
            </c-text>
          </c-flex>
        </c-flex>
      </template>

      <c-flex my="10px" align-items="center" justify="space-between">
        <c-text mt="10px" font-size="16px" font-weight="700" color="#333">
          Asupan Gizi
        </c-text>
        <c-button
          v-if="isNutri"
          variant-color="brand"
          border-radius="50px"
          as="router-link"
          :to="{ name: 'nutri.nutrition-edit' }"
        >
          <c-image
            mr="10px"
            :src="require('@/assets/icon-pencil.svg')"
            alt="icon"
          />
          Edit
        </c-button>
      </c-flex>

      <c-box v-if="isAsupanGiziEmpty">
        <EmptyState title="Asupan gizi belum terisi" />
      </c-box>
      <c-flex
        v-else
        :flex-dir="['column', 'row']"
        flex-wrap="wrap"
        grid-gap="10px"
      >
        <card-kalori
          v-for="asupan in _asupanGizi"
          :key="asupan.title"
          :title="asupan.title"
          :pointSekarang="asupan.pointSekarang"
          :pointKebutuhan="asupan.pointKebutuhan"
          :warningText="asupan.catatan"
          :satuan="asupan.satuan"
          v-chakra
          :w="['auto', '360px']"
        />
      </c-flex>

      <card-kondisi-tubuh :kondisi-tubuh="kondisiTubuh" v-chakra mt="20px" />
      <card-saran
        v-if="saran != null"
        :saran="saran"
        v-chakra
        mt="20px"
        @edit="
          $router.push({
            name: 'nutri.nutrition-advice-edit',
          })
        "
      />
    </c-flex>
    <card-ilustrasi v-chakra mt="20px" />
  </c-flex>
</template>

<script>
import { mapState } from "vuex";
import isNutriMixin from "@/utils/isNutri-mixins";
import Progress from "./progress.vue";
import CardKalori from "./card-kalori.vue";
import CardKondisiTubuh from "./card-kondisi-tubuh.vue";
import CardSaran from "./card-saran.vue";
import CardIlustrasi from "./card-ilustrasi.vue";
import EmptyState from "./empty-state.vue";
import { removeNull } from "@/utils/filters";

export default {
  name: "AhliGiziNonEmpty",
  components: {
    Progress,
    CardKalori,
    CardKondisiTubuh,
    CardSaran,
    CardIlustrasi,
    EmptyState,
  },
  mixins: [isNutriMixin],
  data() {
    return {
      nutritionistName: "",
    };
  },
  computed: {
    ...mapState({
      profileGizi(s) {
        return this.isNutri ? s.mealPlan.profileGizi : s.clients.profileGizi;
      },
      asupanGizi(s) {
        return this.isNutri ? s.mealPlan.asupanGizi : s.clients.asupanGizi;
      },
      kondisiTubuh(s) {
        return this.isNutri ? s.mealPlan.kondisiTubuh : s.clients.kondisiTubuh;
      },
      saran(s) {
        return this.isNutri ? s.mealPlan.saran : s.clients.saran;
      },
    }),
    progressValue() {
      let min = 13;
      let max = 33;
      let cur = this.bmi;
      // let min = this.profileGizi?.idealWeightMinimum
      // let max = this.profileGizi?.idealWeightMaximum
      // let cur = this.profileGizi?.weight

      let progress = ((cur - min) * 100) / (max - min);

      // to make progress value stay inside it's box
      if (progress >= 100) progress = 99;
      if (progress < 0) progress = 1;

      return progress;
    },
    bmi() {
      return this.profileGizi?.bodyMassIndex;
    },
    statusGizi() {
      let statusGizi = this.profileGizi?.nutritionStatus
      if (statusGizi != null) {
        switch (statusGizi) {
          case "obesity":
            return "Obesitas";
          case "pre_obesity":
            return "Preobesitas";
          case "below_normal":
            return "Kurang Gizi";
          case "normal":
          default:
            return "Normal";
        }
      }

      let bmi = this.bmi;
      // Data based on https://www.omnicalculator.com/health/bmi#bmi-ranges
      if (bmi <= 18.5) return "Kurang Gizi";
      if (bmi > 18.5 && bmi <= 25) return "Normal";
      if (bmi > 25 && bmi <= 30) return "Preobesitas";
      if (bmi > 30) return "Obesitas";
      return "Obsesitas";
    },
    _asupanGizi() {
      if (this.asupanGizi == null) return [];
      return [
        {
          title: "Energi",
          pointSekarang: this.asupanGizi.currentEnergyIntake,
          pointKebutuhan: this.asupanGizi.energyIntakeNeeds,
          catatan: this.asupanGizi.energyIntakeNotes,
          satuan: 'kalori',
        },
        {
          title: "Protein",
          pointSekarang: this.asupanGizi.currentProteinIntake,
          pointKebutuhan:
            this.asupanGizi.proteinIntakeNeedPerWeight *
            this.profileGizi?.weight,
          catatan: this.asupanGizi.proteinIntakeNotes,
          satuan: 'gram',
        },
        {
          title: "Lemak",
          pointSekarang: this.asupanGizi.currentFatIntake,
          pointKebutuhan: this.asupanGizi.fatIntakeNeeds,
          catatan: this.asupanGizi.fatIntakeNotes,
          satuan: 'gram',
        },
        {
          title: "Karbohidrat",
          pointSekarang:
            this.asupanGizi.currentCarbohydrateIntake ??
            this.asupanGizi.currentcarbohydrateIntake,
          pointKebutuhan: this.asupanGizi.carbohydrateIntakeNeeds,
          catatan: this.asupanGizi.carbohydrateIntakeNotes,
          satuan: 'gram',
        },
        {
          title: "Serat",
          pointSekarang: this.asupanGizi.currentFiberIntake,
          pointKebutuhan: this.asupanGizi.fiberIntakeNeeds,
          catatan: this.asupanGizi.fiberIntakeNotes,
          satuan: 'gram'
        },
      ];
    },
    isAsupanGiziEmpty() {
      return Object.keys(this.asupanGizi ?? {}).length === 0;
    },
    isKondisiGizikuEmpty() {
      return this.bmi === "0.00" || this.bmi == null;
    },
    isEmpty() {
      return this.isAsupanGiziEmpty && this.isKondisiGizikuEmpty;
    },
  },
  async mounted() {
    if (this.clientId && this["auth::role"] === "nutritionist") {
      this.axios
        .get(`/v1/nutritionist`)
        .then((r) => r.data.data)
        .then((it) => {
          this.nutritionistName = it.fullName;
          if (it.title != null) {
            this.nutritionistName += `, ${removeNull(it.title)}`;
          }
        });
    }
    if (this["auth::role"] === "client") {
      let programs = await this.$store.dispatch(
        "profileProgram/listHistoryPrograms"
      );
      let program = programs?.find((it) => it.isLatest);
      let nutritionist = program.nutritionist;
      this.nutritionistName = `${nutritionist.firstName} ${nutritionist.lastName}`;
      if (nutritionist.title != null) {
        this.nutritionistName += `, ${nutritionist.title}`;
      }
    }
  },
  filters: {
    toFixed(value) {
      return Number(value).toFixed(2);
    },
  },
};
</script>
