<template>
  <c-flex flex-dir="column" flex="1">
    <c-flex mb="10px" px="20px" align-items="center" justify="space-between">
      <c-text font-family="heading" font-size="18px" font-weight="700">
        Profil Gizi
      </c-text>
      <c-button
        v-if="!isNutri"
        variant="outline"
        variant-color="brand"
        rounded="lg"
        as="router-link"
        :to="{ query: { intro: 1 } }"
      >
        <c-text>Info</c-text>
        <c-image
          ml="5px"
          :src="require('@/assets/ic-help-filled.svg')"
          alt="icon help"
        />
      </c-button>
      <c-button
        v-if="isNutri"
        variant-color="brand"
        border-radius="50px"
        as="router-link"
        :to="{ name: 'nutri.profile-client-edit' }"
      >
        <c-image
          mr="10px"
          :src="require('@/assets/icon-pencil.svg')"
          alt="icon"
        />
        Edit
      </c-button>
    </c-flex>
    <Empty v-if="isEmpty" v-chakra mt="80px" />
    <NonEmpty v-if="!isEmpty" />
  </c-flex>
</template>

<script>
import { mapActions, mapState } from "vuex";
import isNutriMixin from "@/utils/isNutri-mixins";
import clientIdMixin from "@/utils/clientId-mixins";
import Empty from "./empty.vue";
import NonEmpty from "./non-empty.vue";

export default {
  name: "HealthProfilePage",
  components: {
    Empty,
    NonEmpty,
  },
  mixins: [isNutriMixin, clientIdMixin],
  computed: {
    ...mapState({
      profileGizi(s) {
        return this.isNutri ? s.mealPlan.profileGizi : s.clients.profileGizi;
      },
      asupanGizi(s) {
        return this.isNutri ? s.mealPlan.asupanGizi : s.clients.asupanGizi;
      },
      kondisiTubuh(s) {
        return this.isNutri ? s.mealPlan.kondisiTubuh : s.clients.kondisiTubuh;
      },
    }),
    isEmpty() {
      return [this.profileGizi, this.kondisiTubuh, this.asupanGizi].every(
        (it) => it == null
      );
    },
  },
  methods: {
    ...mapActions({
      getProfileGizi: "clients/getProfileGizi",
      getAsupanGizi: "clients/getAsupanGizi",
      getKondisiTubuh: "clients/getKondisiTubuh",
      getSaran: "clients/getSaran",
      n_getProfileGizi: "mealPlan/getProfileGizi",
      n_getAsupanGizi: "mealPlan/getAsupanGizi",
      n_getKondisiTubuh: "mealPlan/getKondisiTubuh",
      n_getSaran: "mealPlan/getSaran",
      // listClient: "clients/listClient",
      getClient: "clients/getClientById",
    }),
  },
  async mounted() {
    console.log('programId ==', this.programId);

    if (this.programId != null) {
      await Promise.all([
        this.getProfileGizi(this.programId),
        this.getAsupanGizi(this.programId),
        this.getKondisiTubuh(this.programId),
        this.getSaran(this.programId),
      ]);
    } else if (this.isNutri && this.clientId != null) {
      this.getClient(this.clientId);
      this.n_getProfileGizi(this.clientId);
      this.n_getAsupanGizi(this.clientId);
      this.n_getKondisiTubuh(this.clientId);
      this.n_getSaran(this.clientId);
    } else {
      this.getProfileGizi();
      this.getAsupanGizi();
      this.getKondisiTubuh();
      this.getSaran();
    }
  },
};
</script>
